<template>
  <div class="w10 h-40"></div>
  <div class="identitybox">

     <div class="labelbox mb-18 jc-c pb-10">
    <!-- <div class="line"></div> -->
      {{ props.entInfo.companyName }} 
    </div>
    <div class="labelbox mb-18">
      <div class="line"></div>
      企业相关信息
    </div>
    <formList class="mb-40" :FormDatas="state.FormDatas" :labelWidth="150" @submit="submitEmit" ref="formListRef" />
    <!-- <read ref="readRef"/> -->
    <oabutton v-model:loading="state.loading" class="m-0-a mt-8" width='462' height='48' title="下一步" CSStype=2
      style="border-radius: 4px;font-size: 18px;" @buttonclick="nextClick"></oabutton>
  </div>
</template>
<script setup>
import { ref, unref, reactive, defineEmits, onMounted, nextTick, watch } from "vue";
import { validChinaName, validPhone, validIdcard } from "@/utils/server/validate.js";
import { httpToken } from "@/utils/request";
import { selectById } from "@/utils/server/getdata.js"
import { useStore } from "vuex";
import qs from "qs";
// import read from './read.vue'//阅读协议
import formList from '../../formList.vue'//form 表单组件
import { handleMessage } from "@/utils/server/confirm";
const props = defineProps({
  entInfo: {
    type: Object,
    required: true
  }, 
  userInfo: {
    type: Object,
    required: true
  },
  choiceIdentity: {
    type: Number,
    default: 0
  }
})
const store = useStore();
const emit = defineEmits(['setStep'])
// const readRef = ref()//阅读协议ref

// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else {
    if (validPhone(value)) {
      callback()
    } else
      callback(new Error('手机号格式错误'))
  }
}
// 校验身份证格式
const validIdcardData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入身份证'))
  } else {
    if (validIdcard(value)) {
      callback()
    } else
      callback(new Error('身份证格式错误'))
  }
}
// 证件类型：
// SF: 身份证
// JJ:统一社会信用代码/组织机构代码
// JG:军官证
// HK:户口簿
// HZ:护照
// GA:港澳台居民身份证
// WJ:武警身份证
// BM:边境出入境通行证
// HX:回乡证
// QT:其它
const state = reactive({
  information:{},
  loading: false,//loading显示
  choiceIdentity: props.choiceIdentity,//身份选择下标 0 法定代表人 1经办人
  FormDatas: [
    { label: '企业/组织/商户名称：', type: 1, key: 'entName', default: props.entInfo.companyName, disabled: true, rules: [] },
    { label: '统一社会信用代码：', type: 1, key: 'unCreditCode', default: props.entInfo.unCreditCode, disabled: true, rules: [] },
    { label: '法定代表人姓名：', type: 1, key: 'juridicalName', default: props.entInfo.leagalPerson,  disabled: true, rules: [] },
    // { label: '法定代表人证件类型：', type: 2, key: 'juridicalIDType', default: props.entInfo.leagalIDType, disabled: true, options: [{ label: '身份证', value: 'SF' }], rules: [{ required: true, message: '请选择法定代表人证件类型：' }] },
    // { label: '法定代表人证件号：', type: 1, key: 'juridicalID', default: props.entInfo.leagalID, disabled: true, rules: [{ validator: validIdcardData, trigger: 'blur' }] },
    // { label: '法定代表人手机号：', type: 1, key: 'juridicalPhone', default: props.entInfo.leagalPhone, disabled: true, rules: [{ validator: validPhoneData, trigger: 'blur' }] },
    { label: '企业对公账户账号', type: 1, key: 'accountNo', default: '', rules: [{ required: true, message: '请输入企业对公账户账号' }] },
    { label: '企业开户银行名称', type: 1, key: 'accountBank', default: '', rules: [{ required: true, message: '请输入企业开户银行名称' }] },
  ],//当前表单数据
})


const formListRef = ref()
const nextClick=()=>{
  // if (unref(readRef).state.isread == true) {
    unref(formListRef).submitForm()
  // } else {
  //   unref(readRef).jitter()
  //   handleMessage('请先阅读并同意协议!')
  // }
}
const submitEmit = (el) => {
  if (el) {
    saveData()
    // // choiceIdentity: 0 身份选择下标 0 法定代表人 1经办人
    // if (state.choiceIdentity == 0) {
    //   if(unref(formListRef).formModel.juridicalPhone==JSON.parse(window.sessionStorage.user).user_info.phone){
    //           saveData()
    //   }else{
    //       handleMessage('请选择经办人进行认证','info')
    //   }
    // } else {
    //   // 经办人情况 触发经办人表单校验
    //   unref(formListRef2).submitForm()
    // }
  } else {
    handleMessage("信息填写有误，请确认")
  }
}
const submitEmit2 = (el) => {
  if (el) {
    // 调用经办人保存接口 然后下一步
    saveData()
  }
}
const saveData = (() => {
  let json={
    companyId: store.state.realName.companyId,//企业id
    companyName: props.entInfo.companyName,
    creditCode: props.entInfo.unCreditCode,
    choiceIdentity: 0,//身份选择（0：法定代表人，1：经办人）
    juridicalName: props.entInfo.leagalPerson,//法定代表人姓名：
    // juridicalIDType: unref(formListRef).formModel.juridicalIDType,//法定代表人证件类型：
    // juridicalID: unref(formListRef).formModel.juridicalID,//法定代表人证件号：
    // juridicalPhone: unref(formListRef).formModel.juridicalPhone,//法定代表人证件号：
    accountNo: unref(formListRef).formModel.accountNo,
    accountBank: unref(formListRef).formModel.accountBank,
    ctidmode: 7,
  }
  state.loading = true
  httpToken({
    method: "post",
    url: '/admin/certification/enterpriseSecond',
    data: json
  }).then((res) => {
    if (res.code == 200) {
      selectById(res.data,2).then(res2 => {
        // 法定代表人认证无需经过对公
        emit('setStep', 2)
        state.loading = false
			})
      .catch(err => {
        state.loading = false
      })
    }
  }).catch(err => {
    state.loading = false
  })
})

onMounted(()=>{
  // changeIdentity(state.choiceIdentity)
// getinformation()
console.log(state.FormDatas[3])
})
</script>
<style lang="scss" scoped >
@import "@/styles/color/value.scss";

.identitybox {
  width: 860px;
  margin: 0 auto;
  padding: 46px 40px;
  background: #FFFFFF;
  border-radius: 12px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  .identitys {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .identityItem {
      width: 300px;
      height: 90px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px rgba(154, 154, 154, 0.16);
      border-radius: 8px;

      .icon {
        width: 14px;
        height: 14px;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 50%;
      }

      .title {
        line-height: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }

      .label {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
      }
    }

    .identityItemac {
      box-shadow: none;
      border: 1px solid $active-theme;

      .icon {
        background: #fff;
        border: 4px solid $active-theme;
        box-shadow: 0px 0px 1px 0px $active-theme;
      }
    }

    .identityItem+.identityItem {
      margin-left: 20px;
    }
  }

  .labelbox {
    display: flex;

    .line {
      width: 4px;
      height: 20px;
      background: $active-theme;
      margin-right: 10px;
    }

    font-size: 16px;
    font-family: PingFangSC-Semibold,
    PingFang SC;
    font-weight: 600;
  }
}
</style>