<template>
  <div class="informationbox pb-30" v-if="state.pageIndex==0">
    <div class="mt-44 mb-24">请选认证类型</div>
    <div class="dp-f jc-c mb-36">
      <types @Emit="(ind) => {state.ctidType = ind}" :typeIndex="state.ctidType"></types>
    </div>
    <formList class="mb-50" :FormDatas="entData" @submit="submitEmit"
      ref="formListRef" />
    <!-- <formList class="mb-50" :FormDatas="state.FormDatas1" v-else-if="state.typeIndex == 1" @submit="submitEmit"
      ref="formListRef" />
    <formList class="mb-50" :FormDatas="state.FormDatas2" v-else-if="state.typeIndex == 2" @submit="submitEmit"
      ref="formListRef" /> -->
    <oabutton v-model:loading="state.loading" class="m-0-a" width='462' height='48' title="下一页" CSStype=2
      style="border-radius: 4px;font-size: 18px;" @buttonclick="saveData"></oabutton>
  </div>
  <div v-else-if="state.pageIndex==1">
    <identity v-if="state.ctidType == 4" :ent-info="state.entInfo" :user-info="user" :choice-identity="state.choiceIdentity" @setStep="emit('setStep', $event)"/>
    <payment v-else-if="state.ctidType == 7" :ent-info="state.entInfo" :user-info="user" :choice-identity="state.choiceIdentity" @setStep="emit('setStep', $event)"/>
  </div>
</template>
<script setup>
import types from './types.vue'//选择组织类型
import dragbox from './dragbox.vue'//拖拽上传
import formList from '../formList.vue'//form 表单组件
import identity from "./information/identity.vue"//身份选择
import payment from "./information/payment.vue" //对公转账
import { validChinaName, validSocialCode,validIdcard, validPhone } from "@/utils/server/validate.js";
import { ref, unref, reactive, defineEmits,onMounted,nextTick, computed } from "vue";
import { uploadFile } from "@/utils/server/upload.js"
import { handleMessage } from "@/utils/server/confirm.js"
import { httpToken } from "@/utils/request";
import { selectById } from "@/utils/server/getdata.js"
import { useStore } from "vuex";
import qs from "qs";
const store = useStore();
const user = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})
// 校验中文姓名
const validChinaNameData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入真实姓名'))
  } else {
    if (validChinaName(value)) {
      callback()
    } else
      callback(new Error('真实姓名格式错误'))
  }
}
// 校验统一社会信用代码
const validSocialCodeData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入统一社会信用代码'))
  } else {
    if (validSocialCode(value)) {
      callback()
    } else
      callback(new Error('统一社会信用代码格式错误'))
  }
}
// 校验身份证格式
const validIdcardData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入身份证'))
  } else {
    if (validIdcard(value)) {
      callback()
    } else
      callback(new Error('身份证格式错误'))
  }
}
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else {
    if (validPhone(value)) {
      callback()
    } else
      callback(new Error('手机号格式错误'))
  }
}

const formListRef = ref()
const state = reactive({
  loading: false,//loading显示
  ctidType: 4,  //认证方式 4：企业四要素   7： 对公转账
  pageIndex:-1,//页码数
  FormDatas: [
    { label: '企业/组织/商户名称：', type: 1, key: 'companyName', default: '', rules: [{ required: true, message: '请填写企业名称：' }] },
    { label: '统一社会信用代码：', type: 1, key: 'unCreditCode', default: '', rules: [{ validator: validSocialCodeData, trigger: 'blur' }] },
    { label: '法定代表人姓名：', type: 1, key: 'leagalPerson', default: '', rules: [{ validator: validChinaNameData, trigger: 'blur' }] },
    { label: '法定代表人证件类型：', type: 2, key: 'leagalIDType', default: 'SF', options: [{ label: '身份证', value: 'SF' }], rules: [{ required: true, message: '请选择法定代表人证件类型：' }] },
    { label: '法定代表人证件号：', type: 1, key: 'leagalID', default: '', rules: [{ validator: validIdcardData, trigger: 'blur' }] },
    { label: '法定代表人手机号：', type: 4, key: 'leagalPhone', default: '', rules: [{ validator: validPhoneData, trigger: 'blur' }] },

    // { label: '法定代表人手机号：', type: 1, key: 'leagalPhone', default: '', rules: [{ validator: validPhoneData, trigger: 'blur' }] },
  ],//当前表单数据
  entInfo: {},
  choiceIdentity: 0,
});
const entData = computed(() => {
  return state.FormDatas
})

const emit = defineEmits(['setStep'])
const saveData = (() => {
  unref(formListRef).submitForm()
})
// 执行第一步保存
const enterpriseFirst = () => {
  let json = {
    companyName: unref(formListRef).formModel.companyName,//公司名称
    unCreditCode: unref(formListRef).formModel.unCreditCode,//统一社会信用代码：
    leagalPerson: unref(formListRef).formModel.leagalPerson,//法定代表人名称
    // leagalPhone: unref(formListRef).formModel.leagalPhone,//法定代表人手机号
    leagalIDType: unref(formListRef).formModel.leagalIDType,
    leagalID: unref(formListRef).formModel.leagalID,
    leagalPhone: unref(formListRef).formModel.leagalPhone,
    companyId: store.state.realName.companyId,//企业id
    authenticationType: state.ctidType
  }
  state.entInfo = json
  if (json.leagalPerson === unref(user).chnname) {
    state.choiceIdentity = 0
  } else {
    state.choiceIdentity = 1
  }
  console.log("choice identity:", state.choiceIdentity)
  httpToken({
    method: "post",
    url: '/admin/certification/enterpriseFirst',
    data: json
  }).then((res) => {
    if (res.code == 200) {
      selectById(res.data,2).then(res2 => {
				state.pageIndex=1
        state.loading = false
			})
      .catch(err => {
        state.loading = false
      })
    }
  }).catch(err => {
    state.loading = false
  })
}
// 表单校验返回结果
const submitEmit = (el) => {
  if (el) {
    state.loading = true
    enterpriseFirst()
    return
  }
}
const setCompanyName=()=>{
  let json=[{column:"company_id",type:"eq",value:store.state.realName.companyId}]
  httpToken({
    method: "post",
    url: '/admin/sys-company/find',
    data: qs.stringify({
      paramData: JSON.stringify(json),
    }),
  }).then((res) => {
      console.log(res.data)
      state.FormDatas[0].default = res.data.companyName
      state.FormDatas[0].disabled = true
      state.FormDatas[1].default = res.data.creditCode
      state.FormDatas[1].disabled = true
      state.FormDatas[2].default = res.data.juridicalPersonName
      state.pageIndex = 0
  })
}

onMounted(()=>{
  console.log(state.pageIndex, state.ctidType)
  // 基本信息认证完成直接跳转至 法定代表人信息认证页面
  if(store.state.realName.companyInfo && store.state.realName.companyInfo.authenticationState==1) {
    state.pageIndex = 1
    state.ctidType = store.state.realName.companyInfo.authenticationType
    state.entInfo = {
      companyName: store.state.realName.companyInfo.companyName,
      unCreditCode: store.state.realName.companyInfo.creditCode,
      leagalPerson: store.state.realName.companyInfo.juridicalName,
      leagalIDType: store.state.realName.companyInfo.juridicalIDType,
      leagalID: store.state.realName.companyInfo.juridicalID,
      leagalPhone: store.state.realName.companyInfo.juridicalPhone
    }
    if (state.entInfo.leagalPerson === unref(user).chnname) {
      state.choiceIdentity = 0
    } else {
      state.choiceIdentity = 1
    }
  } else {
    setCompanyName()
}
  // setCompanyName()
})
</script>
<style lang="scss" scoped >
.informationbox {
  width: 1120px;
  margin: 0 auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  .labelbox {
    color: #333333;

    span {
      color: #FF0000;
      margin-right: 5px;
    }

    .example {
      color: #1890FF;
    }
  }
}
</style>