<template>
    <div class="typeItem dp-f pl-40 pr-26 ml-50 mr-50 ai-c cu-p" :class="state.typeIndex == el.mode? 'typeItemac' : ''"
        v-for="(el,ind) in state.types" :key="ind" @click="state.typeIndex = el.mode,emit('Emit', el.mode)">
        <img class="w-44 h-44 mr-22" :src="el.imgurl">
        <div>
            <div class="title fs-16 mb-10">{{ el.title }}</div>
            <div>{{ el.test }}</div>
        </div>
    </div>
</template>
<script setup>
import { reactive,defineEmits } from "vue";
const props = defineProps({
    // 当前选择组织下标
    typeIndex:{
        type: Number,
        default: () => {
            return 1
        }
    },
});
const emit = defineEmits(['Emit'])
const state = reactive({
    types: [
        { title: '企业法人核验', test: '适用于法人本人或经办人认证', imgurl: require('@/assets/img/realname/type0.png'), mode: 4},
        { title: '对公转账核验', test: '适用于xxxxxxxxxxxxxxxxxxx', imgurl: require('@/assets/img/realname/type2.png'), mode:  7},
        // { title: '其他', test: '非工商组织类型，如民办非企业单位/事业单位等组织团体', imgurl: require('@/assets/img/realname/type3.png') },
    ],
    typeIndex:props.typeIndex,
});
</script>
<style lang="scss" scoped >
.typeItem {
    width: 360px;
    height: 110px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(154, 154, 154, 0.22);
    border-radius: 8px;
    color: #666666;
}

.typeItemac {
    border: 1px solid #EEC774;
    background: #FAF9DE;
}
</style>